import React from 'react';
import firebase from 'firebase/app';
import { FirebaseAuth } from 'react-firebaseui';

const auth = firebase.auth();

export default function GoogleSignInView() {
  //this is our config for FirebaseAuth
  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccess: () => {
        window.location.href = '/app/dashboard';
      },
    },
  };

  return (
    <>
      <FirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </>
  );
}
