import React from 'react';
import { useQuery } from 'react-query';
import config from 'src/config';
import { getAllFoodDetails } from 'src/modals/food_details';

const useQueryListAllFoodDetails = () => {
  return useQuery(['list-all-food-details'], () => {
    let result = getAllFoodDetails();
    console.log('useQueryListAllFoodDetails', result);
    return result;
  });
};

export default useQueryListAllFoodDetails;
