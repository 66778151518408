import React from 'react';
import ShowDebug from 'src/components/ShowDebug';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Slide,
} from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import test_data from './test_data';
import init_advertisement_tag from './init_new_advertisement_tag';

import { saveAdvertisementTag } from 'src/modals/advertisement_tag';

import { useSnackbar } from 'notistack';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditRow({
  refreshAddAdvertisementTag,
  advertisement_tag_to_edit,
  open,
  setOpen,
}) {
  let [debug, setDebug] = React.useState(null);
  let [advertisement_tag_data, setAdvertisementTag_data] = React.useState(
    init_advertisement_tag
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleUpdateAdvertisementTags = e => {
    console.log('advertisement_tag_data', advertisement_tag_data);
    saveAdvertisementTag(advertisement_tag_to_edit.id, advertisement_tag_data)
      .then(() => {
        setOpen(false);
        refreshAddAdvertisementTag();
      })
      .catch(err => {
        enqueueSnackbar('error during saving advertisement tag', {
          variant: 'error',
        });
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = e => {
    setAdvertisementTag_data({
      ...advertisement_tag_data,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    if (advertisement_tag_to_edit != null) {
      setAdvertisementTag_data(advertisement_tag_to_edit.data);
    }
    console.log('advertisement_tag_to_edit init', advertisement_tag_to_edit);
  }, [advertisement_tag_to_edit]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={false}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ShowDebug>
          <pre>{JSON.stringify(advertisement_tag_to_edit, null, 2)}</pre>
        </ShowDebug>
        <DialogTitle id="alert-dialog-slide-title">
          <Box mb="1rem">
            <Typography variant="h4" color="textPrimary">
              {active_lang.ADVERTISEMENT_TAG_EDIT_TITLE}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Box m="0.5rem">
                  <TextField
                    fullWidth
                    label={active_lang.ADVERTISEMENT_TAG_TITLE}
                    name="title"
                    onInput={handleUpdate}
                    required
                    value={advertisement_tag_data.title}
                    variant="outlined"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m="0.5rem">
                  <TextField
                    fullWidth
                    label={active_lang.ADVERTISEMENT_TAG_REMARKS}
                    name="remarks"
                    onInput={handleUpdate}
                    required
                    value={advertisement_tag_data.remarks}
                    variant="outlined"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {active_lang.ADMIN_USER_UPDATE_CANCEL}
            </Button>
            <Button onClick={handleUpdateAdvertisementTags} color="primary">
              {active_lang.ADMIN_USER_UPDATE_OK}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
