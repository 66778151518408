import React from 'react';
import { Box } from '@material-ui/core';

import SelectAdAction from './SelectAdAction';
import SelectAdDestinationSubform from './SelectAdDestinationSubform';
import ShowDebug from '../ShowDebug';

import { isUndefined } from 'lodash-es';
import active_lang from 'src/langs/jp_en';

export default function AdConfigureSlotFoodMenuSubform({
  formik,
  food_details,
}) {
  return (
    <>
      {isUndefined(food_details) ? (
        <>{active_lang.CANNOT_READ_FOOD_MENU_FROM_RESTAURANT}</>
      ) : (
        <>
          <ShowDebug>AdConfigureSlotFoodMenuSubform</ShowDebug>

          <SelectAdAction formik={formik} service_class={{ id: 32 }} />

          <SelectAdDestinationSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      )}
    </>
  );
}
