let ADMIN_USER_EMAIL = '用戶のemail';
let ADMIN_USER_PASSWORD = '用戶のpassword';

let ADMIN_NOT_A_VALID_EMAIL = 'not a valid email';
let ADMIN_NOT_ENOUGH_LONG = 'password must be minimum 6 chars long';

export default {
  ADMIN_USER_EMAIL,
  ADMIN_USER_PASSWORD,
  ADMIN_NOT_A_VALID_EMAIL,
  ADMIN_NOT_ENOUGH_LONG,
};
