import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '@material-ui/core';

import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';

const EnqueueAlert = React.createContext();

const ENQUEUE_SUCCESS_CONFIG = {
  variant: 'success',
};
const ENQUEUE_ERROR_CONFIG = {
  variant: 'error',
};
const ENQUEUE_WARNING_CONFIG = {
  variant: 'warning',
};

const notistackRef = React.createRef();

const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

export const EnqueueAlertProvider = ({ children }) => {
  const EnqueueAlertWrapper = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const enqueue_success = msg => {
      enqueueSnackbar(msg, ENQUEUE_SUCCESS_CONFIG);
    };

    const enqueue_warning = msg => {
      enqueueSnackbar(msg, ENQUEUE_WARNING_CONFIG);
    };

    const enqueue_error = msg => {
      enqueueSnackbar(msg, ENQUEUE_ERROR_CONFIG);
    };

    const showEnqueue = {
      success: enqueue_success,
      warning: enqueue_warning,
      error: enqueue_error,
    };

    return (
      <EnqueueAlert.Provider value={{ showEnqueue }}>
        {children}
      </EnqueueAlert.Provider>
    );
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={key => (
        <Button onClick={onClickDismiss(key)} style={{ color: 'white' }}>
          <CancelIcon />
        </Button>
      )}
    >
      <EnqueueAlertWrapper>{children}</EnqueueAlertWrapper>
    </SnackbarProvider>
  );
};

export default EnqueueAlert;
