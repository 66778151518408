import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import { AuthProvider } from 'src/Firebase/context';
import BUILD_INFO from 'src/consts/URLS/data.json';

import { EnqueueAlertProvider } from './contexts/EnqueueAlertContext';

import App from './App';
import theme from './theme';

import * as serviceWorker from './serviceWorker';

console.log('hello from menymeny.com', BUILD_INFO.GIT_COMMIT);

if (process.env.NODE_ENV != 'development') {
  console.log = () => {};
  console.assert = () => {};
  console.warn = () => {};

  if (window.location.href.search('web.app') == -1) {
    console.debug = () => {};
    console.error = () => {};
  }

  alert = () => {};
}

const queryClient = new QueryClient();

ReactDOM.render(
  <>
    <Suspense fallback={<div>Loading...</div>}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <EnqueueAlertProvider>
                <App />
              </EnqueueAlertProvider>
            </BrowserRouter>
          </ThemeProvider>
        </QueryClientProvider>
      </AuthProvider>
    </Suspense>
  </>,
  document.querySelector('#root')
);

serviceWorker.unregister();
