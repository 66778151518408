import React from 'react';
import { Box } from '@material-ui/core';

import lynked_white_svg from 'src/assets/images/tabi-life-white.svg';

const Logo = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'row',
          alignItems: 'flex-end',
        }}
      >
        <Box
          style={{
            width: '200px',
            height: '46px',
          }}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${lynked_white_svg})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></Box>
        </Box>
        <Box style={{ paddingLeft: '1em', color: 'white', lineHeight: '26px' }}>Admin panel</Box>
      </Box>
    </>
  );
};

export default Logo;
