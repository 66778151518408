import React from 'react';

import CurrencyFormat from 'react-currency-format';

function ShowYen({ value_yen }) {
  return (
    <>
      <CurrencyFormat
        value={value_yen}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'¥'}
      />
    </>
  );
}

export default ShowYen;
