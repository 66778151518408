import React from 'react';
import { Box } from '@material-ui/core';

import AdminSelectMenyAdConfigureSlot from './AdminSelectMenyAdConfigureSlot';
import SelectRestaurant from './SelectRestaurant';

import { isEqual } from 'lodash-es';
import SelectAdAction from './SelectAdAction';
import SelectAdDestination from './SelectAdDestination';
import SelectAdDestinationSubform from './SelectAdDestinationSubform';
import ShowDebugJson from 'src/components/ShowDebugJson';
import SelectFoodDetail from './SelectFoodDetail';
import SelectFoodMenuCategory from './SelectFoodMenuCategory';
import AdConfigureSlotFoodMenuSubform from './AdConfigureSlotFoodMenuSubform';
import AdConfigureSlotOrderListSubform from './AdConfigureSlotOrderListSubform';
import AdConfigureSlotFoodDetailSubform from './AdConfigureSlotFoodDetailSubform';

import ShowDebug from 'src/components/ShowDebug';

function getDataById(all_data, wanted_id) {
  let found = false;
  let found_data = {};
  for (let i = 0; i < all_data.length; i++) {
    if (all_data[i].id == wanted_id) {
      found = true;
      found_data = all_data[i];
      break;
    }
  }

  if (found) {
    return found_data;
  } else {
    return undefined;
  }
}

export default function AdConfigureSlotSubform({ formik, food_details }) {
  switch (formik.values.form_ad_window) {
    case 'food_menu':
      return (
        <>
          <ShowDebug>AdConfigureSubform</ShowDebug>
          <AdConfigureSlotFoodMenuSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      );

    case 'order_list':
      return (
        <>
          <ShowDebug>AdConfigureSubform</ShowDebug>
          <AdConfigureSlotOrderListSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      );

    case 'food_detail':
      return (
        <>
          <ShowDebug>AdConfigureSubform</ShowDebug>
          <AdConfigureSlotFoodDetailSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      );

    default:
      return <></>;
  }
}
