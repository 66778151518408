import React from 'react';

import 'react-quill/dist/quill.snow.css';
import './react-quill-style.css';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import RestaurantQRTest from './RestaurantQRTest';

import config from 'src/config';
import active_lang from 'src/langs/jp_en';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ color: [] }, { background: [] }],
    ['link'],
    ['clean'],
  ],
};

export default function RestaurantLinkDialog({ open, setOpen, link_user }) {
  let [user_service_category, setUserServiceCategory] = React.useState(
    config.DEFAULT_NEW_USER_CATEGORY
  );

  let [user_remarks, setUserRemarks] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateUser = (email, password, meny_class) => {
    fetch(config.CREATE_USER_ENDPOINT, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, meny_class }),
    })
      .then(response => {
        return response.json();
      })
      .then(res_json => {
        // setResponse(JSON.stringify(res_json, null, 2));
        // handleListAllUsers();
        if (res_json.result == 'create user failed') {
          alert(JSON.stringify(res_json, null, 2));
        } else {
          alert(
            'add user done',
            JSON.stringify(`email: ${email}, password: ${password}`)
          );
          setOpen(false);
        }
      });
  };

  const handleAddUser = e => {
    let email = document.forms['create-user-form'].email.value;
    let password = document.forms['create-user-form'].password.value;

    handleCreateUser(email, password, user_service_category);
  };
  const handleChange = e => {
    let e_name = e.target.name;
    document.forms['create-user-form'][e_name].value = e.target.value;
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={'lg'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item md={12} style={{ textAlign: 'center' }}>
              <Box>{'qr demo list'}</Box>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CardContent>
              <RestaurantQRTest user_id={link_user.uid} />
            </CardContent>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
