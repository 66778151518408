let SIMPLE_VERSIONING = 'V_20210106_1';
let RESTAURANT_PROJECT = '(PROJ: meny-light-demo)';
let RESTAURANT_STRING = '食店控制介面 / Restaurant Managment Panel';

let TEST_ALERT_SHOW_DURATION = 6000;
let APP_LANDING_PAGE = '/app/overview';

let DEVELOPER_MODE = true;

let DB_TABLE_ORDER_LIST = 'order';

let CHART_DEFAULT_DATE_RANGE = 7;
let DEFAULT_CUSTOM_DATE_RANGE_VALUE = 10;

let DEFAULT_NEW_USER_CATEGORY = 1;

let FIREBASE_FUNCTION_URL =
  'https://us-central1-lynked-demo-tryout.cloudfunctions.net';
let LOCAL_FIREBASE_FUNCTION_URL =
  'http://localhost:5001/lynked-demo-tryout/us-central1';

let FIREBASE_FUNCTION_HOST =
  process.env.NODE_ENV == 'development'
    ? LOCAL_FIREBASE_FUNCTION_URL
    : FIREBASE_FUNCTION_URL;

let API_ENDPOINT = FIREBASE_FUNCTION_HOST + '/api';
let ADMIN_ENDPOINT = FIREBASE_FUNCTION_HOST + '/api';
let USER_MNGT_ENDPOINT = FIREBASE_FUNCTION_HOST + '/api';
let CREATE_USER_ENDPOINT = USER_MNGT_ENDPOINT + '/create_user';
let LIST_USER_ENDPOINT = USER_MNGT_ENDPOINT + '/list_all_users';
let UPDATE_USER_ENDPOINT = USER_MNGT_ENDPOINT + '/update_user';
let UPDATE_USER_PASSWORD_ENDPOINT =
  USER_MNGT_ENDPOINT + '/update_user_password';
let DELETE_USER_ENDPOINT = USER_MNGT_ENDPOINT + '/delete';

let REMOVE_ADVERTISEMENT_TAGS = API_ENDPOINT + '/removeAdvertisementTags';

const CHECK_LOGIN_TIMEOUT_MS = 1000;

export { ADMIN_ENDPOINT, CHECK_LOGIN_TIMEOUT_MS };

export default {
  ADMIN_ENDPOINT,
  REMOVE_ADVERTISEMENT_TAGS,
  DELETE_USER_ENDPOINT,
  UPDATE_USER_PASSWORD_ENDPOINT,
  UPDATE_USER_ENDPOINT,
  DEFAULT_NEW_USER_CATEGORY,
  TEST_ALERT_SHOW_DURATION,
  SIMPLE_VERSIONING,
  RESTAURANT_STRING,
  APP_LANDING_PAGE,
  DEVELOPER_MODE,
  DB_TABLE_ORDER_LIST,
  RESTAURANT_PROJECT,
  CHART_DEFAULT_DATE_RANGE,
  DEFAULT_CUSTOM_DATE_RANGE_VALUE,
  CREATE_USER_ENDPOINT,
  LIST_USER_ENDPOINT,
};
