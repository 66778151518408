import React from 'react';
import { useQuery } from 'react-query';
import config from 'src/config';
import { listAllRestaurants } from 'src/modals/restaurant_config';

const useQueryListAllRestaurants = () => {
  return useQuery(
    ['list-all-restaurants'],
    () => {
      return listAllRestaurants().then(a_d => {
        let options = [];
        a_d
          .sort((a, b) => a.data.name - b.data.name)
          .forEach(d =>
            options.push({ name: d.data.name, id: d.id, data: d.data })
          );
        return options;
      });
    },
    // disable to avoid loading all image on every window focus in edit_restaurant_info form
    { refetchOnWindowFocus: false }
  );
};

export default useQueryListAllRestaurants;
