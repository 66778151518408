import React from 'react';
import { useMutation } from 'react-query';
import { getNewAdvertisementId } from 'src/modals/advertisement_config';

// MutateNewAdvertisementId
export default function useMutateNewAdvertisementId() {
  const mutation = useMutation(() => {
    return getNewAdvertisementId();
  });

  return mutation;
}
