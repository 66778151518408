import 'firebase/firestore';
import db_config from '../configs/db_config';
import db from '../Firebase/db';

const ADVERTISEMENT_INFO_REF = db.collection(
  db_config.DB_TABLE_ADVERTISEMENT_INFO
);

function loadAdvertisementFoodDetails() {
  return ADVERTISEMENT_INFO_REF.get();
}

function updateAdvertisementFoodDetails(id, data) {
  return ADVERTISEMENT_INFO_REF.doc(id).set(data, { merge: true });
}

function reserveNewAdvertisementFoodDetail() {
  return ADVERTISEMENT_INFO_REF.doc().id;
}

function addAdvertisementFoodDetail(config_to_add) {
  return ADVERTISEMENT_INFO_REF.add(config_to_add);
}

let ADVERTISEMENT_FOOD_DETAIL_TEST = [
  {
    FoodDetail0: {
      name: 'ホテルスペシャルランチ',
      image_url:
        'https://images.unsplash.com/photo-1524859330668-c357331384f5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2256&q=80',
      max_quantity_per_order: 10,
      food_id: 'food_0',
      price: '40.0',
      description:
        '<p>鮮やかな野菜たっぷり</p><p>特製の胡麻醤油ソースで香ばしいたまらない</p><p><br></p><p>表示金額は税金・サービス料を含めた料金でございます。</p><p>食物アレルギーをお持ちのお客様は、お申し付けください。</p>',
      update_time: '2020-01-02 11:22:33',
      long_name: '胡麻醤油ソース付き',
      unit_price: '580',
      id: 'FoodDetail0',
      min_quantity_per_order: 1,
    },
  },
  {
    FoodDetail1: {
      name: 'ホテルモントレ銀座20周年',
      image_url:
        'https://images.unsplash.com/photo-1524859330668-c357331384f5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2256&q=80',
      max_quantity_per_order: 10,
      food_id: 'food_0',
      price: '40.0',
      description:
        '<p>鮮やかな野菜たっぷり</p><p>特製の胡麻醤油ソースで香ばしいたまらない</p><p><br></p><p>表示金額は税金・サービス料を含めた料金でございます。</p><p>食物アレルギーをお持ちのお客様は、お申し付けください。</p>',
      update_time: '2020-01-02 11:22:33',
      long_name: '胡麻醤油ソース付き',
      unit_price: '580',
      id: 'FoodDetail0',
      min_quantity_per_order: 1,
    },
  },
  {
    FoodDetail2: {
      name: 'ホテルモントレ銀座20周年',
      image_url:
        'https://images.unsplash.com/photo-1524859330668-c357331384f5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2256&q=80',
      max_quantity_per_order: 10,
      food_id: 'food_0',
      price: '40.0',
      description:
        '<p>鮮やかな野菜たっぷり</p><p>特製の胡麻醤油ソースで香ばしいたまらない</p><p><br></p><p>表示金額は税金・サービス料を含めた料金でございます。</p><p>食物アレルギーをお持ちのお客様は、お申し付けください。</p>',
      update_time: '2020-01-02 11:22:33',
      long_name: '胡麻醤油ソース付き',
      unit_price: '580',
      id: 'FoodDetail0',
      min_quantity_per_order: 1,
    },
  },
];

export {
  ADVERTISEMENT_FOOD_DETAIL_TEST,
  loadAdvertisementFoodDetails,
  updateAdvertisementFoodDetails,
  reserveNewAdvertisementFoodDetail,
  addAdvertisementFoodDetail,
};
