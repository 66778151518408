import BugReportIcon from '@material-ui/icons/BugReport';

import { Settings as SettingsIcon } from 'react-feather';

const ENV_PUBLIC_URL = process.env.PUBLIC_URL;

const debug_items = [
  {
    href: `${ENV_PUBLIC_URL}/app/documentation`,
    icon: SettingsIcon,
    title: 'Documentation',
  },
  {
    href: `${ENV_PUBLIC_URL}/app/advertisement_food_detail`,
    icon: BugReportIcon,
    title: '廣告 => food detail',
  },
  {
    href: `${ENV_PUBLIC_URL}/app/debug_edit_advertisements`,
    icon: BugReportIcon,
    title: 'debug_edit_advertisements',
  },
  {
    href: `${ENV_PUBLIC_URL}/app/test_page`,
    icon: BugReportIcon,
    title: 'test_page',
  },
];

export default debug_items;
