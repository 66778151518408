import { select_values } from 'src/modals/restaurant_config';

import { RESTAURANT_OPEN_STATE_PREPARING } from 'src/consts/RESTAURANT_OPEN_STATE';

const restaurant_initial_value = {
  name: '',
  meny_service_class: select_values.meny_service_class.MENY_SERVICE_MENY,
  enable_rest_ad_control: false,
  isSkippableToAssignTable: false,
  isEnableBellRing: false,
  restaurant_profile_pic: '',
  status: select_values.status.active,
  restaurant_operators: [],
  restaurant_open_id: 'new_restaurant_open_id',
  restaurant_open_state: RESTAURANT_OPEN_STATE_PREPARING,
  restaurant_active: true,
  lineup: {
    lineup_username_placeholder: '名前',
    lineup_remarks_placeholder: '備考',
    max_num_of_adult: 100,
    max_num_of_children: 100,
  },
  opening_schedule: {
    MON: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
    TUE: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
    WED: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
    THU: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
    FRI: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
    SAT: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
    SUN: {
      enabled: false,
      utc_start_time: '2021-06-12T01:00:15.000Z',
      utc_end_time: '2021-06-12T14:00:30.000Z',
    },
  },
};

export { restaurant_initial_value };
