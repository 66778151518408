import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useSnackbar } from 'notistack';
import { deleteAdvertisementById } from 'src/modals/advertisement_config';

import active_lang from 'src/langs/jp_en';

import { LYNKED_RED } from 'src/consts/colors';

import { disableAdvertisementTag } from 'src/modals/advertisement_tag';

import removeAdvertisementTags from 'src/apis/removeAdvertisementTags';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ShowConfirmDeleteAdvertisementTags({
  open,
  setOpen,
  advertisement_tag_to_delete,
  setAdvertisementTagToDelete,
  refreshAddAdvertisementTag,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [button_delete_text, setButtonDeleteText] = React.useState(
    active_lang.BUTTON_DELETE
  );
  const [delete_in_progress, setDeleteInProgress] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleReset = () => {
    setAdvertisementTagToDelete(null);
    setDeleteInProgress(false);
    setButtonDeleteText(active_lang.DELETE_IN_PROGRESS);
  };

  const handleClose = () => {
    handleReset();
    setOpen(false);
  };

  const handleUserConfirmDelete = () => {
    console.log('advertisement_tag_to_delete', advertisement_tag_to_delete);
    setDeleteInProgress(true);
    setButtonDeleteText(active_lang.DELETE_IN_PROGRESS);

    disableAdvertisementTag(advertisement_tag_to_delete.id)
      .then(() => {
        return removeAdvertisementTags(advertisement_tag_to_delete.id);
      })
      .then(() => {
        enqueueSnackbar(active_lang.DELETED, { variant: 'error' });
      })
      .then(() => {
        refreshAddAdvertisementTag();
      })
      .then(() => {
        handleClose();
      })
      .catch(err => {
        enqueueSnackbar(active_lang.ERROR_FOUND_DURING_DELETE, {
          variant: 'error',
        });
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {active_lang.CONFIRM_DELETE_ADVERTISEMENT_TAG}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <br />
            {`${active_lang.ADVERTISEMENT_TAG_TITLE}:${advertisement_tag_to_delete.data.title}`}
            <br />
            {`${active_lang.ADVERTISEMENT_TAG_REMARKS}:${advertisement_tag_to_delete.data.remarks}`}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.BUTTON_CANCEL}
          </Button>
          <Button
            disabled={delete_in_progress}
            variant="contained"
            onClick={handleUserConfirmDelete}
            style={{ backgroundColor: LYNKED_RED }}
          >
            {button_delete_text}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function ConfirmDeleteAdvertisementTags({
  open,
  setOpen,
  advertisement_tag_to_delete,
  setAdvertisementTagToDelete,
  refreshAddAdvertisementTag,
}) {
  return (
    <>
      {advertisement_tag_to_delete == null ? (
        <></>
      ) : (
        <ShowConfirmDeleteAdvertisementTags
          open={open}
          setOpen={setOpen}
          advertisement_tag_to_delete={advertisement_tag_to_delete}
          setAdvertisementTagToDelete={setAdvertisementTagToDelete}
          refreshAddAdvertisementTag={refreshAddAdvertisementTag}
        />
      )}
    </>
  );
}
