import React from 'react';

import { AuthContext } from 'src/Firebase/context';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { isNull } from 'lodash-es';

import active_lang from 'src/langs/jp_en';
import ListRestaurants from './ListRestaurants';
import Page from 'src/components/Page';
import test_restaurants from './data.js';
import Toolbar from './Toolbar';
import useQueryListAllRestaurants from 'src/hooks/useQueryListAllRestaurants';
import useQueryListAllUsers from 'src/hooks/useQueryListAllUsers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function EditRestaurantInfo() {
  const classes = useStyles();
  let { user } = React.useContext(AuthContext);
  let [restaurants, setRestaurants] = React.useState(test_restaurants);

  let list_all_user_result = useQueryListAllUsers();
  let query_list_all_restaurants = useQueryListAllRestaurants();

  React.useEffect(() => {
    if (query_list_all_restaurants.isLoading) {
    } else {
      setRestaurants(query_list_all_restaurants.data);
    }
  }, [query_list_all_restaurants]);

  return (
    <Page
      className={classes.root}
      title={active_lang.ADMIN_EDIT_RESTAURANT_INFO}
    >
      <Container maxWidth={false}>
        {query_list_all_restaurants.isLoading ||
        list_all_user_result.isLoading ||
        isNull(user) ? (
          <>{active_lang.LOADING_RESTAURANTS_AND_USERS}</>
        ) : (
          <>
            <Toolbar
              list_all_user_result={list_all_user_result}
              refetch={query_list_all_restaurants.refetch}
            />
            <Box mb="1rem">
              <Typography variant="h4" color="textPrimary">
                {active_lang.ADMIN_EDIT_RESTAURANT_INFO}
              </Typography>
            </Box>
            <Box>
              <ListRestaurants
                restaurants={restaurants}
                query_list_all_restaurants={query_list_all_restaurants}
                list_all_user_result={list_all_user_result}
              />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
}
