import React from 'react';

import SelectFoodDetail from './SelectFoodDetail';

export default function GotoFoodDetailSubform({ formik, food_details }) {
  return (
    <>
      <SelectFoodDetail formik={formik} food_details={food_details} />
    </>
  );
}
