import 'firebase/firestore';

const field_values = {
  event_type: {
    ADMIN_USER_LOGIN: 'ADMIN_USER_LOGIN',
    ADMIN_USER_LOGOUT: 'ADMIN_USER_LOGOUT',
  },
};

export { field_values };
