import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';

import { AuthContext } from 'src/Firebase/context';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function LoginView() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    if (user && user.email === 'louis.law@lynked.co.jp') {
      window.location.href = '/';
    }
  }, [user]);

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" style={{ textAlign: 'center' }}>
          <Formik
            initialValues={{
              email: 'admin1@lynked.co.jp',
              password: '123456',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required'),
            })}
            onSubmit={() => {
              navigate('/app/dashboard', { replace: true });
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>

                <Grid item xs={12} md={12}>
                  <Button
                    id="login-by-google"
                    fullWidth
                    startIcon={<GoogleIcon />}
                    // onClick={signInWithGoogle}
                    size="large"
                    variant="contained"
                    component={RouterLink}
                    to="/google_signin"
                    color="primary"
                  >
                    {active_lang.LOGIN_USING_SOCIAL_NETWORK}
                  </Button>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}
