import React from 'react';

import GotoFoodMenuSubform from './GotoFoodMenuSubform';
import GotoFoodDetailSubform from './GotoFoodDetailSubform';

export default function SelectAdDestinationSubform({ formik, food_details }) {
  switch (formik.values.form_ad_action) {
    case 'food_menu':
      return (
        <>
          <GotoFoodMenuSubform formik={formik} food_details={food_details} />
        </>
      );
    case 'food_detail':
      return (
        <>
          <GotoFoodDetailSubform formik={formik} food_details={food_details} />
        </>
      );
    default:
      return (
        <>
          <pre>{JSON.stringify(formik.values)}</pre>
        </>
      );
  }
}
