let ADMIN_EDIT_RESTAURANT_INFO = 'レストランを編集する';
let ENABLE_AD_CONFIG_ON_CMS = 'ENABLED AD CONFIG ON CMS';
let RESTAURANT_ACTIVE = 'RESTAURANT ACTIVE';
let ADMIN_ADD_NEW_RESTAURANT = '新しいレストラン';

let RESTAURANT_NAME_IS_REQUIRED = 'RESTAURANT_NAME_IS_REQUIRED';
let AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED = 'AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED';
let ADMIN_RESTAURANT_NAME = 'レストラン';

let TRY_CREATE_RESTAURANT = 'try create restaurant';
let INITIALIZE_NEW_RESTAURANT = 'initialize new restaurant';
let CREATE_RESTAURANT_DONE = 'create restaurant done';
let ERROR_DURING_CREATE_RESTAURANT = 'error during create restaurant';

let LOADING_USERS = 'loading users';

let LOADING_RESTAURANTS_AND_USERS = 'loading restaurants and users';
let UPDATING_RESTAURANTS_AND_USERS = 'updating restaurants and users';

export default {
  RESTAURANT_ACTIVE,
  UPDATING_RESTAURANTS_AND_USERS,
  LOADING_RESTAURANTS_AND_USERS,
  LOADING_USERS,
  ERROR_DURING_CREATE_RESTAURANT,
  CREATE_RESTAURANT_DONE,
  ADMIN_RESTAURANT_NAME,
  ADMIN_EDIT_RESTAURANT_INFO,
  ENABLE_AD_CONFIG_ON_CMS,
  ADMIN_ADD_NEW_RESTAURANT,
  RESTAURANT_NAME_IS_REQUIRED,
  AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED,
  TRY_CREATE_RESTAURANT,
  INITIALIZE_NEW_RESTAURANT,
};
