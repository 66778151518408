import React from 'react';
import { useQuery } from 'react-query';
import config from 'src/config';

const useQueryListAllUsers = () => {
  return useQuery(
    ['list-all-users'],
    async () => {
      const response = await fetch(config.LIST_USER_ENDPOINT);

      if (!response.ok) {
        console.log('error during list ing all users');
      }

      return response.json();
    },
    { refetchOnWindowFocus: false }
  );
};

export default useQueryListAllUsers;
