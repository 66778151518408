import React from 'react';

import {
  IconButton,
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import ShowDebug from 'src/components/ShowDebug';
import ShowYen from 'src/components/ShowYen';
import EditAdvertisementFoodDetail from './EditAdvertisementFoodDetail';
import ConfirmDeleteAdvertisementFoodDetail from './ConfirmDeleteAdvertisementFoodDetail';

import active_lang from 'src/langs/jp_en';

function TableLoading() {
  return <>table loading</>;
}

function ShowTable({
  advertisement_food_details,
  handleListAllAdvertisementFoodDetails,
}) {
  let [open_edit, setOpenEdit] = React.useState(false);
  let [food_detail_to_edit, setFoodDetailToEdit] = React.useState(null);

  let [open_confirm_delete, setOpenConfirmDelete] = React.useState(false);
  let [food_detail_to_delete, setFoodDetailToDelete] = React.useState(null);

  const handleListAll = () => {};
  const handleEditClick = (e, id_data) => {
    // alert('helloworld');
    setFoodDetailToEdit(id_data);
    setOpenEdit(true);
    console.log('handleEditClick', 'id_data', id_data);
  };

  return (
    <>
      <ShowDebug>
        <pre>{JSON.stringify(advertisement_food_details, null, 2)}</pre>
      </ShowDebug>

      <EditAdvertisementFoodDetail
        open={open_edit}
        setOpen={setOpenEdit}
        id_data={food_detail_to_edit}
        handleListAll={handleListAllAdvertisementFoodDetails}
      />

      <ConfirmDeleteAdvertisementFoodDetail
        open={open_confirm_delete}
        setOpen={setOpenConfirmDelete}
        food_detail_to_delete={food_detail_to_delete}
        handleListAll={handleListAllAdvertisementFoodDetails}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{`actions`}</TableCell>
            <TableCell>{active_lang.ADMIN_ADVERTISEMENT_NAME}</TableCell>
            <TableCell>{`image_url`}</TableCell>
            <TableCell style={{ width: '45%' }}>{`description`}</TableCell>
            <TableCell>{`unit_price`}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {advertisement_food_details.sort().map(id_data => {
            let advertisement_food_detail = id_data.data;
            return (
              <>
                <TableRow key={id_data.id} name={id_data.id} hover>
                  <TableCell>
                    <IconButton onClick={e => handleEditClick(e, id_data)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={e => {}}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{advertisement_food_detail.name}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${advertisement_food_detail.image_urls[0]})`,
                      }}
                    ></div>
                  </TableCell>
                  <TableCell>{advertisement_food_detail.description}</TableCell>
                  <TableCell>
                    <ShowYen value_yen={advertisement_food_detail.unit_price} />
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default function ListAdvertisementFoodDetail({
  advertisement_food_details,
  handleListAllAdvertisementFoodDetails,
}) {
  return (
    <>
      {advertisement_food_details == null ? (
        <TableLoading />
      ) : (
        <ShowTable
          advertisement_food_details={advertisement_food_details}
          handleListAllAdvertisementFoodDetails={
            handleListAllAdvertisementFoodDetails
          }
        />
      )}
    </>
  );
}
