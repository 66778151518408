import React from 'react';

import config from 'src/config';

function removeAdvertisementTags(advertisement_tag_id) {
  return fetch(
    `${config.REMOVE_ADVERTISEMENT_TAGS}?advertisement_tags_id=${advertisement_tag_id}`
  )
    .then(res => res.json())
    .then(res_json => {});
}
export default removeAdvertisementTags;
