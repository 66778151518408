import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import config from 'src/config';

import { deleteAdvertisementById } from 'src/modals/advertisement_config';

export default function useMutateDeleteAdvertisementById() {
  const mutation = useMutation(advertisement_id_to_delete => {
    return deleteAdvertisementById(advertisement_id_to_delete);
  });

  return mutation;
}
