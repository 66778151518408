import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isEqual, isUndefined } from 'lodash-es';

import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
} from '@material-ui/core';

import { LYNKED_RED } from 'src/consts/colors';
import { service_category } from 'src/modals/restaurant_config';
import { useStyles, Transition } from 'src/consts/styles';

import active_lang from 'src/langs/jp_en';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutoCompleteRestaurantOperators from './AutoCompleteRestaurantOperators';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EnqueueAlert from 'src/contexts/EnqueueAlertContext';
import ImageHandler from './ImageHandler';
import ShowDebug from 'src/components/ShowDebug';
import ShowDebugJson from '../ShowDebugJson';
import TextField from '@material-ui/core/TextField';
import useFirestoreUpload from 'src/hooks/useFirestoreUpload';
import useMutateInitFoodDetailForNewRestaurant from 'src/hooks/useMutateInitFoodDetailForNewRestaurant';
import useMutateReserveNewRestaurantId from 'src/hooks/useMutateReserveNewRestaurantId';
import useUpdateRestaurantInfo from 'src/hooks/useUpdateRestaurantInfo';
import WarningIcon from '@material-ui/icons/Warning';

import { restaurant_initial_value } from './restaurant_initial_value';

const FIRESTORE_FILEPATH = `/restaurant_profile_pic`;

const RESTAURANT_ACTIVE_DEFAULT_VALUE = true;

export default function AddNewRestaurantDialog({
  open,
  setOpen,
  list_all_user_result,
  refetch,
}) {
  let classes = useStyles();
  let mutation_reserve_new_restaurant_id = useMutateReserveNewRestaurantId();
  let mutateFirestoreUpload = useFirestoreUpload();
  let mutationUpdateRestaurantInfo = useUpdateRestaurantInfo();
  let mutateInitFoodDetailForNewRestaurant = useMutateInitFoodDetailForNewRestaurant();
  let { showEnqueue } = React.useContext(EnqueueAlert);

  const formik = useFormik({
    initialValues: restaurant_initial_value,
    validationSchema: Yup.object({
      name: Yup.string().required(active_lang.RESTAURANT_NAME_IS_REQUIRED),
      restaurant_operators: Yup.array().min(
        1,
        active_lang.AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED
      ),
    }),
    onSubmit: async values => {
      let image_base64 = values.restaurant_profile_pic;
      console.log('AddNewRestaurantDialog', 'values', values);

      let new_rest_id = await mutation_reserve_new_restaurant_id.mutateAsync();
      let file_path = `${FIRESTORE_FILEPATH}/${new_rest_id}/profile`;

      showEnqueue.warning(active_lang.TRY_CREATE_RESTAURANT);
      return mutateFirestoreUpload
        .mutateAsync({ file_path, file_base64: image_base64 })
        .then(data => {
          return mutationUpdateRestaurantInfo.mutateAsync({
            rest_id: new_rest_id,
            payload: { ...values, restaurant_profile_pic: data.download_url },
          });
        })
        .then(() => {
          showEnqueue.warning(active_lang.INITIALIZE_NEW_RESTAURANT);
          return mutateInitFoodDetailForNewRestaurant.mutateAsync(new_rest_id);
        })
        .then(() => {
          showEnqueue.success(active_lang.CREATE_RESTAURANT_DONE);
          refetch();
        })
        .then(() => {
          handleClose();
        })
        .catch(err => {
          showEnqueue.error(active_lang.ERROR_DURING_CREATE_RESTAURANT);
          console.log('AddNewRestaurantDialog', 'err', err);
        });
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <>
      {isUndefined(list_all_user_result) || list_all_user_result.isLoading ? (
        <>{active_lang.LOADING_RESTAURANTS_AND_USERS}</>
      ) : (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth
          >
            <form
              onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <DialogContent>
                <Box mt="1rem">
                  <FormControl fullWidth>
                    <TextField
                      id="name"
                      {...formik.getFieldProps('name')}
                      className={classes.TextInput}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: { padding: '1rem' } }}
                      label={active_lang.ADMIN_RESTAURANT_NAME}
                      variant="outlined"
                      fullWidth
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Box className={classes.field_error_highlight}>
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors.name}</Box>
                      </Box>
                    ) : null}
                  </FormControl>
                </Box>

                <Box mt={'1rem'}>
                  <FormControl fullWidth>
                    <ImageHandler
                      id="restaurant_profile_pic"
                      {...formik.getFieldProps('restaurant_profile_pic')}
                    />
                  </FormControl>
                </Box>

                <Box mt="1rem">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="meny_service_class"
                      {...formik.getFieldProps('meny_service_class')}
                      options={service_category}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="RestaurantServiceCategorySelect"
                          variant="outlined"
                        />
                      )}
                      disableClearable
                      onChange={(event, newValue) =>
                        formik.setFieldValue('meny_service_class', newValue)
                      }
                    />
                  </FormControl>
                </Box>

                <Box mt="1rem">
                  <FormControl fullWidth>
                    <AutoCompleteRestaurantOperators
                      formik={formik}
                      list_all_user_result={list_all_user_result}
                    />
                    {formik.touched.restaurant_operators &&
                    formik.errors.restaurant_operators ? (
                      <Box
                        style={{
                          color: LYNKED_RED,
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          paddingLeft: '0.25rem',
                          paddingTop: '0.25rem',
                          paddingBottom: '0.25rem',
                        }}
                      >
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors.restaurant_operators}</Box>
                      </Box>
                    ) : null}
                  </FormControl>
                </Box>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="enable_rest_ad_control"
                              checked={
                                isUndefined(formik.values) ||
                                isUndefined(
                                  formik.values.enable_rest_ad_control
                                )
                                  ? false
                                  : formik.values.enable_rest_ad_control
                              }
                              // checked={enable_rest_ad_control}
                              {...formik.getFieldProps(
                                'enable_rest_ad_control'
                              )}
                              inputProps={{
                                'aria-label':
                                  active_lang.ENABLE_AD_CONFIG_ON_CMS,
                              }}
                            />
                          }
                          label={active_lang.ENABLE_AD_CONFIG_ON_CMS}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isSkippableToAssignTable"
                              checked={formik.values.isSkippableToAssignTable}
                              onChange={formik.handleChange}
                            />
                          }
                          label="SKIP TABLE ASSIGN"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="restaurant_active"
                              checked={
                                isUndefined(formik.values) ||
                                isUndefined(formik.values.restaurant_active)
                                  ? RESTAURANT_ACTIVE_DEFAULT_VALUE
                                  : formik.values.restaurant_active
                              }
                              {...formik.getFieldProps('restaurant_active')}
                              inputProps={{
                                'aria-label': active_lang.RESTAURANT_ACTIVE,
                              }}
                            />
                          }
                          label={active_lang.RESTAURANT_ACTIVE}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isEnableBellRing"
                              checked={formik.values.isEnableBellRing}
                              onChange={formik.handleChange}
                            />
                          }
                          label="ENABLE BELL RING FEATURE"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  id="cancel-new-restaurant"
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                  type="button"
                  color="primary"
                >
                  {active_lang.ADMIN_USER_UPDATE_CANCEL}
                </Button>
                <Button
                  id="submit-new-restaurant"
                  disabled={
                    formik.isSubmitting ||
                    !formik.dirty ||
                    !isEqual(formik.errors, {})
                  }
                  type={'submit'}
                  color="primary"
                  variant="contained"
                >
                  {active_lang.ADMIN_USER_UPDATE_OK}
                </Button>
              </DialogActions>
            </form>
            <ShowDebug>
              <ShowDebugJson>{JSON.stringify(formik.values)}</ShowDebugJson>
              <ShowDebugJson>{formik.isSubmitting}</ShowDebugJson>
              <ShowDebugJson>{!formik.dirty}</ShowDebugJson>
              <ShowDebugJson>{!isEqual(formik.errors, {})}</ShowDebugJson>
              <ShowDebugJson>
                {formik.isSubmitting || !isEqual(formik.errors, {})}
              </ShowDebugJson>
            </ShowDebug>
          </Dialog>
        </>
      )}
    </>
  );
}
