import React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { select_values } from 'src/modals/advertisement_config';
import active_lang from 'src/langs/jp_en';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import ShowDebug from 'src/components/ShowDebug';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: {
    color: LYNKED_RED,
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
}));

const getAdvertisementByRestId = (advertisement_list, rest_id) => {
  return advertisement_list.filter(a => a.data.rest_id == rest_id);
};

function SelectFoodDetailOnly({ formik, all_advertisement_result }) {
  let classes = useStyles();

  let rest_id = formik.values.rest_id;
  let rest_advertisement_list = getAdvertisementByRestId(
    all_advertisement_result.data,
    rest_id
  );

  console.log('rest_advertisement_list', rest_advertisement_list);

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<InputBase />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={e => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.FOOD_DETAIL.name:
              formik.setFieldValue('form_ad_destination', 'food_detail');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
        defaultValue={'please_select'}
      >
        <MenuItem value={select_values.ad_window.FOOD_DETAIL.name}>
          {active_lang.AD_FOOD_DETAIL}
          <ShowDebug>{'food_detail'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectFoodMenuOnly({ formik, all_advertisement_result }) {
  let classes = useStyles();

  let rest_id = formik.values.rest_id;
  let rest_advertisement_list = getAdvertisementByRestId(
    all_advertisement_result.data,
    rest_id
  );

  console.log('rest_advertisement_list', rest_advertisement_list);

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<InputBase />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={e => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.FOOD_DETAIL.name:
              formik.setFieldValue('form_ad_destination', 'food_detail');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
        defaultValue={'please_select'}
      >
        <MenuItem value={'please_select'} disabled>
          {active_lang.AD_PLEASE_SELECT}
        </MenuItem>
        <MenuItem value={select_values.ad_window.FOOD_MENU.name}>
          {active_lang.AD_FOOD_MENU}
          <ShowDebug>{'food_menu'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectFoodMenuAndFoodDetail({ formik, all_advertisement_result }) {
  let classes = useStyles();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<InputBase />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={e => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.FOOD_DETAIL.name:
              formik.setFieldValue('form_ad_destination', 'food_detail');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
        defaultValue={'please_select'}
      >
        <MenuItem value={'please_select'} disabled>
          {active_lang.AD_PLEASE_SELECT}
        </MenuItem>
        <MenuItem value={select_values.ad_window.FOOD_MENU.name}>
          {active_lang.AD_FOOD_MENU}
          <ShowDebug>{'food_menu'}</ShowDebug>
        </MenuItem>
        <MenuItem value={select_values.ad_window.FOOD_DETAIL.name}>
          {active_lang.AD_FOOD_DETAIL}
          <ShowDebug>{'food_detail'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function FilteredSelectList({ formik, all_advertisement_result }) {
  let classes = useStyles();

  let rest_id = formik.values.rest_id;
  let rest_advertisement_list = getAdvertisementByRestId(
    all_advertisement_result.data,
    rest_id
  );

  console.log('rest_advertisement_list', rest_advertisement_list);

  switch (rest_advertisement_list.length) {
    case 1:
      if (rest_advertisement_list[0].data.ad_window.name == 'food_menu') {
        return (
          <>
            {/* <button onClick={testSetFieldValue}>testSetFieldValue</button> */}
            <SelectFoodDetailOnly
              formik={formik}
              all_advertisement_result={all_advertisement_result}
            />
          </>
        );
      } else if (
        rest_advertisement_list[0].data.ad_window.name == 'food_detail'
      ) {
        return (
          <>
            <SelectFoodMenuOnly
              formik={formik}
              all_advertisement_result={all_advertisement_result}
            />
          </>
        );
      } else {
        return (
          <>
            <SelectFoodMenuAndFoodDetail
              formik={formik}
              all_advertisement_result={all_advertisement_result}
            />
          </>
        );
      }

    default:
      return (
        <>
          <SelectFoodMenuAndFoodDetail
            formik={formik}
            all_advertisement_result={all_advertisement_result}
          />
        </>
      );
  }
}

export default function AdminSelectMenyLightAdConfigureSlot({
  formik,
  all_advertisement_result,
}) {
  let classes = useStyles();

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <ShowDebug>
            {('AD_WINDOW', 'AdminSelectMenyLightAdConfigureSlot')}
          </ShowDebug>

          <Box>{active_lang.AD_CONFIGURE_SLOT}</Box>

          <FilteredSelectList
            formik={formik}
            all_advertisement_result={all_advertisement_result}
          />
        </FormControl>
      </Box>
    </>
  );
}
