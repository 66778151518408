import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import Page from 'src/components/Page';
import active_lang from 'src/langs/jp_en';
import ListUsers from './ListUsers';
import { useStyles } from './style';
import Toolbar from './Toolbar';

import useQueryListAllUsers from 'src/hooks/useQueryListAllUsers';

export default function EditUserInfo() {
  const classes = useStyles();

  let list_all_user_result = useQueryListAllUsers();

  const handleListAllUsers = () => {
    list_all_user_result.refetch();
  };

  return (
    <Page className={classes.root} title={active_lang.ADMIN_EDIT_USER_INFO}>
      <Container maxWidth={false}>
        {list_all_user_result.isLoading ? (
          <>loading</>
        ) : (
          <>
            <Toolbar handleListAllUsers={handleListAllUsers} />
            <Box mb="1rem">
              <Typography variant="h4" color="textPrimary">
                {active_lang.ADMIN_EDIT_USER_INFO}
              </Typography>
            </Box>
            <Box>
              <ListUsers
                users={list_all_user_result.data.users}
                handleListAllUsers={handleListAllUsers}
              />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
}
