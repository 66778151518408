import { FormControl } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import WarningIcon from '@material-ui/icons/Warning';
import { useFormik } from 'formik';
import React from 'react';
import { LYNKED_RED } from 'src/consts/colors';
import EnqueueAlert from 'src/contexts/EnqueueAlertContext';
import MutateCreateUser from 'src/hooks/MutateCreateUser';
import active_lang from 'src/langs/jp_en';
import * as Yup from 'yup';
import ShowDebugJson from '../ShowDebugJson';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNewUserDialog({
  open,
  setOpen,
  handleListAllUsers,
}) {
  let mutationCreateUser = MutateCreateUser();
  let { showEnqueue } = React.useContext(EnqueueAlert);

  const handleClose = () => {
    setOpen(false);
    handleListAllUsers();
  };

  const formik = useFormik({
    initialValues: { email: '', user_password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(active_lang.ADMIN_NOT_A_VALID_EMAIL),
      user_password: Yup.string().min(6, active_lang.ADMIN_NOT_ENOUGH_LONG),
    }),
    onSubmit: values => {
      showEnqueue.warning('try create user');
      mutationCreateUser
        .mutateAsync({
          email: values.email,
          password: values.user_password,
        })
        .then(res => {
          console.log('mutationCreateUser', 'res', res);

          if (res.result != 'create user ok') throw Error(res.reason.message);

          showEnqueue.success(`user added ${JSON.stringify(res, null, 2)}`);
          formik.resetForm();
          handleClose();
        })
        .catch(err => {
          showEnqueue.error(`error during create user ${err}`);
          console.error('mutationCreateUser', err);
        });
    },
  });

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={'lg'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Grid container>
              <Grid item md={12} style={{ textAlign: 'center' }}>
                <Box>{active_lang.ADMIN_ADD_NEW_USER}</Box>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Box mt="1rem">
              <FormControl>
                <TextField
                  id="email"
                  {...formik.getFieldProps('email')}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.ADMIN_USER_EMAIL}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.email && formik.errors.email ? (
                  <Box
                    style={{
                      color: LYNKED_RED,
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '0.25rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                    }}
                  >
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.email}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>
            <Box mt="1rem">
              <FormControl>
                <TextField
                  id="user_password"
                  {...formik.getFieldProps('user_password')}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.ADMIN_USER_PASSWORD}
                  variant="outlined"
                  fullWidth
                  required
                />
                {formik.touched.user_password && formik.errors.user_password ? (
                  <Box
                    style={{
                      color: LYNKED_RED,
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '0.25rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                    }}
                  >
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.user_password}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              onClick={() => {
                formik.resetForm();
                handleClose();
              }}
              color="primary"
            >
              {active_lang.ADMIN_USER_UPDATE_CANCEL}
            </Button>
            <Button
              // disabled={formik.isSubmitting || !formik.dirty}
              type="submit"
              color="primary"
              variant="contained"
            >
              {active_lang.ADMIN_USER_UPDATE_OK}
            </Button>
          </DialogActions>
          <ShowDebugJson>{formik}</ShowDebugJson>
        </form>
      </Dialog>
    </>
  );
}
