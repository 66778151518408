import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import GetQR from 'src/utils/GetQR';
import getEncryptedUserUid from 'src/utils/getEncryptedUserUid';

import { Icon } from '@iconify/react';
import qrcodeIcon from '@iconify-icons/mdi/qrcode';

// import { default_host_ip } from 'src/configs/config';
const default_host_ip = 'localhost';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: '150px',
    maxWidth: '15vw',
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const GridQR = ({ link, text }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Paper className={classes.paper} style={{ padding: '0.5rem' }}>
          <GetQR link={link} text={text} style={{ padding: '10px' }} />
        </Paper>
      </Grid>
    </>
  );
};

export default function RestaurantQRTest({ user_id }) {
  const classes = useStyles();
  const encrypted_user_id = getEncryptedUserUid(user_id);
  const [input_host_ip, setInputHostIp] = React.useState(default_host_ip);
  const [host_ip, setHostIp] = React.useState(default_host_ip);

  const handleChange = event => {
    setSpacing(Number(event.target.value));
  };

  // client
  const host_to_test =
    process.env.NODE_ENV == 'production'
      ? `https://meny-light-app-demo.menymeny.com`
      : `http://${host_ip}:5003`;

  const restaurant_link =
    process.env.NODE_ENV == 'production'
      ? `https://meny-manage.menymeny.com`
      : `http://${host_ip}:8002`;

  const admin_link =
    process.env.NODE_ENV == 'production'
      ? `https://meny-light-admin-demo.menymeny.com`
      : `http://${host_ip}:5002`;

  const menu_link = `${host_to_test}/${user_id}/${user_id}`;
  const getTableLink = table_name => {
    return `${host_to_test}/${user_id}/${user_id}/${table_name}`;
  };

  const [spacing, setSpacing] = React.useState(2);

  const handleHostIpOnChange = e => {
    setInputHostIp(e.target.value);
  };

  const handleRenderOnClick = e => {
    setHostIp(input_host_ip);
  };

  return (
    <>
      <h2 style={{ display: 'none' }}>
        <Icon icon={qrcodeIcon} /> qr list
      </h2>
      <h3 style={{ display: 'none' }}>
        <input onChange={handleHostIpOnChange} value={input_host_ip} />
        <Button onClick={handleRenderOnClick}>Render</Button>
      </h3>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            spacing={8}
            style_del={{ backgroundColor: 'gold' }}
          >
            <GridQR link={restaurant_link} text="restaurant link" />
            <GridQR link={admin_link} text="admin link" />
            <GridQR link={menu_link} text="menu link without table" />
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(table_idx => {
              let table_by_number = `table_${table_idx}`;
              let table_link = getTableLink(table_by_number);
              return <GridQR link={table_link} text={table_by_number} />;
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
