import React from 'react';
import { useQuery } from 'react-query';
import config from 'src/config';
import { listAllKLynkedBottomAdvertisement } from 'src/modals/advertisement_config';

const useQueryAllLynkedBottomAdvertisement = () => {
  return useQuery(['list-all-advertisement'], () => {
    return listAllKLynkedBottomAdvertisement().then(result => {
      return result;
    });
  });
};

export default useQueryAllLynkedBottomAdvertisement;
