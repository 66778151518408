import React from 'react';
import { isUndefined } from 'lodash-es';
import { useFormik } from 'formik';

import ImageHandler from './ImageHandler';
import { FormControl } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import SelectRestaurant from './SelectRestaurant';
import isBase64Image from 'src/utils/isBase64Image';
import isHttpLink from 'src/utils/isHttpLink';

import active_lang from 'src/langs/jp_en';

import { useStyles, Transition } from './styles';
import useFirestoreUpload from 'src/hooks/useFirestoreUpload';

import FormAdConfig from './FormAdConfig';
import ShowDebugJson from 'src/components/ShowDebugJson';
import EnqueueAlert from 'src/contexts/EnqueueAlertContext';
import getRestaurantById from 'src/utils/getRestaurantById';
import getDataById from 'src/utils/getDataById';

import getAdWindowObjByName from 'src/utils/getAdWindowObjByName';
import getAdActionObjByName from 'src/utils/getAdActionObjByName';
import getAdDestination from 'src/utils/getAdDestination';

import useMutateUpdateAdvertisementById from 'src/hooks/useMutateUpdateAdvertisementById';

function initialSelectAdDestination(ad_action, ad_destination) {
  if (ad_action.name == 'food_menu') {
    return ad_destination.cat_id;
  } else if (ad_action.name == 'food_detail') {
    return ad_destination.food_detail_id;
  } else {
    return undefined;
  }
}

export default function EditAdvertisementDialog({
  open,
  setOpen,
  ad_info,
  all_restaurants_result,
  all_food_details_result,
  all_advertisement_result,
  refetch,
}) {
  let classes = useStyles();

  let ad_rest_id = ad_info.data.rest_id;
  let food_details = getDataById(all_food_details_result.data, ad_rest_id).data;

  let mutationUpdateAdvertisementById = useMutateUpdateAdvertisementById();
  let mutateFirestoreUpload = useFirestoreUpload();

  let { showEnqueue } = React.useContext(EnqueueAlert);
  let [ui_disable, setUiDisable] = React.useState(false);

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...ad_info.data,
      form_ad_window: isUndefined(ad_info.data.ad_window)
        ? 'food_menu'
        : ad_info.data.ad_window.name,
      form_ad_action: isUndefined(ad_info.data.ad_action)
        ? 'food_menu'
        : ad_info.data.ad_action.name,
      form_ad_destination: initialSelectAdDestination(
        ad_info.data.ad_action,
        ad_info.data.ad_destination
      ),
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        active_lang.ADVERTISEMENT_NAME_IS_MISSING_ERROR
      ),
      rest_id: Yup.mixed().notOneOf(
        ['please_select'],
        active_lang.AD_INVALID_REST_SELECTED
      ),
      image_urls: Yup.array()
        .test(
          'ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY',
          active_lang.ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY,
          (value, context) => {
            // console.log('image_urls validate', value);
            if (value.length != 3) return false;
            return true;
          }
        )
        .test(
          'not a valid image',
          active_lang.ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY,
          (value, context) => {
            if (
              /^data:.+/.test(value[0]) ||
              /^http:.+/.test(value[0]) ||
              /^https:.+/.test(value[0])
            )
              return true;
            return false;
          }
        ),
      form_ad_destination: Yup.mixed().notOneOf(
        ['please_select'],
        'invalid_ad_destination'
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      showEnqueue.warning(active_lang.UPDATE_ADVERTISEMENT_IN_PROGRESS);
      let rest_id = values.rest_id;
      let advertisement_id = ad_info.id;

      let sanitized_ad_window = getAdWindowObjByName(values.form_ad_window);
      let sanitized_ad_action = {
        ...values.ad_action,
        ...getAdActionObjByName(values.form_ad_action),
      };
      let sanitized_ad_destination = getAdDestination(
        food_details,
        values.form_ad_action,
        values.form_ad_destination
      );
      let sanitized_value = {
        rest_id: values.rest_id,
        is_enabled: true,
        image_urls: values.image_urls,
        title: values.title,
        target: values.target,
        ad_type: 'AD_TYPE_LYNKED_BOTTOM_ADVERTISEMENT',
        restaurant_service_tags: values.restaurant_service_tags,
        ad_action: sanitized_ad_action,
        ad_window: sanitized_ad_window,
        ad_destination: sanitized_ad_destination,
      };

      console.log(
        'EditAdvertisementDialog',
        'sanitized_value',
        sanitized_value
      );

      let file_path = `rest_advertisement/${rest_id}/${advertisement_id}/image_0`;
      console.log('file_path', file_path);
      let image_base64 = sanitized_value.image_urls[0];
      console.log(
        'EditAdvertisementDialog base64 test result',
        isBase64Image(image_base64)
      );

      if (isBase64Image(image_base64)) {
        console.log('EditAdvertisementDialog update Advertisement with image');
        return mutateFirestoreUpload
          .mutateAsync({
            file_path,
            file_base64: image_base64,
          })
          .then(data => {
            return mutationUpdateAdvertisementById.mutateAsync({
              advertisement_id,
              payload: {
                ...sanitized_value,
                rest_id: rest_id,
                image_urls: [data.download_url, '', ''],
              },
            });
          })
          .then(() => {
            showEnqueue.success(active_lang.DELETE_ADVERTISEMENT_DONE);

            refetch();
            handleClose();
          })
          .catch(err => {
            showEnqueue.error(active_lang.DELETE_ADVERTISEMENT_ERROR);
            console.log('err', err);
          });
      } else if (isHttpLink(sanitized_value.image_urls[0])) {
        mutationUpdateAdvertisementById
          .mutateAsync({
            advertisement_id,
            payload: { ...sanitized_value, rest_id: rest_id },
          })
          .then(() => {
            showEnqueue.success(active_lang.UPDATE_ADVERTISEMENT_DONE);
            refetch();
            handleClose();
          })
          .catch(err => {
            showEnqueue.error(active_lang.UPDATE_ADVERTISEMENT_ERROR);
            console.log('err', err);
          });
      } else {
        // NOTE: should not go here as image is considered to be required
        console.log(
          'EditAdvertisementDialog update Advertisement without image'
        );
        mutationUpdateAdvertisementById
          .mutateAsync({
            advertisement_id,
            payload: {
              ...sanitized_value,
              rest_id: rest_id,
              image_urls: ['', '', ''],
            },
          })
          .then(() => {
            // enqueueSnackbar(
            //   active_lang.AD_ADVERTISEMENT_UPDATE_SUCCESS,
            //   ENQUEUE_SUCCESS_CONFIG
            // );
            showEnqueue.success(active_lang.UPDATE_ADVERTISEMENT_DONE);

            refetch();
            handleClose();
          })
          .catch(err => {
            // showEnqueue.error;
            console.log('err', err);
          });
      }

      // setUiDisable(true);]
      formik.setSubmitting(false);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {active_lang.AD_EDIT_ADVERTISEMENT_DIALOG}
        </DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }}>
          <Box mt="1rem">
            <FormControl fullWidth>
              <Box>{active_lang.AD_NAME}</Box>
              <TextField
                id="title"
                {...formik.getFieldProps('title')}
                className={classes.TextInput}
                placeholder={active_lang.AD_NAME}
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: { padding: '1rem' } }}
                style={{ marginTop: '0.5rem' }}
                fullWidth
              />

              {formik.touched.title && formik.errors.title ? (
                <Box className={classes.user_input_error}>
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>{formik.errors.title}</Box>
                </Box>
              ) : null}
            </FormControl>
          </Box>
          <Box mt={'0.5rem'}>
            <FormControl fullWidth>
              <Box>{active_lang.AD_IMAGE}</Box>
              <ImageHandler
                id="image_urls"
                {...formik.getFieldProps('image_urls')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                image_url={
                  formik.values.image_urls && formik.values.image_urls[0]
                }
                edit_show_detail={formik.values}
                setEditShowDetail={`setEditShowDetail`}
              />
              {formik.touched.image_urls && formik.errors.image_urls ? (
                <Box className={classes.user_input_error}>
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>{formik.errors.image_urls}</Box>
                </Box>
              ) : null}
            </FormControl>
          </Box>

          <SelectRestaurant
            formik={formik}
            all_restaurants_result={all_restaurants_result}
          />
          <FormAdConfig
            service_class={
              getRestaurantById(
                all_restaurants_result.data,
                formik.values.rest_id
              ).meny_service_class
            }
            formik={formik}
            food_details={getDataById(
              all_food_details_result.data,
              formik.values.rest_id
            )}
            all_restaurants_result={all_restaurants_result}
            all_advertisement_result={all_advertisement_result}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={ui_disable}
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            color="primary"
          >
            {active_lang.AD_EDIT_CANCEL}
          </Button>
          <Button
            disabled={formik.isSubmitting}
            type={'button'}
            color="primary"
            variant="contained"
            onClick={e => {
              formik.handleSubmit(e);
            }}
          >
            {active_lang.AD_EDIT_CONFIRM}
          </Button>
        </DialogActions>
        <ShowDebugJson>{JSON.stringify(formik.errors)}</ShowDebugJson>
        <ShowDebugJson>{JSON.stringify(formik.values)}</ShowDebugJson>
        <ShowDebugJson>{JSON.stringify(ad_info)}</ShowDebugJson>
      </Dialog>
    </>
  );
}
