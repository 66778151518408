import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, makeStyles } from '@material-ui/core';

import { UserPlus } from 'react-feather';

import AddNewRestaurantDialog from 'src/components/AddNewRestaurantDialog';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, refetch, list_all_user_result, ...rest }) => {
  const classes = useStyles();
  let [open_new_user_dialog, setOpenNewUserDialog] = React.useState(false);
  let [user_info, setUserInfo] = React.useState({
    name: '',
    email: '',
    remarks: '',
  });

  return (
    <>
      <AddNewRestaurantDialog
        open={open_new_user_dialog}
        setOpen={setOpenNewUserDialog}
        refetch={refetch}
        list_all_user_result={list_all_user_result}
      />
      <div className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
          <div style={{ display: 'none' }}>
            <Button className={classes.importButton}>Import</Button>
            <Button className={classes.exportButton}>Export</Button>
          </div>
          <Button
            id="admin-add-new-restaurant"
            color="primary"
            variant="contained"
            startIcon={<UserPlus />}
            onClick={() => {
              setOpenNewUserDialog(true);
            }}
          >
            {active_lang.ADMIN_ADD_NEW_RESTAURANT}
          </Button>
        </Box>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
