let AD_ACTION_LOADING = 'アクションを読み込み中';
let AD_ACTIONS = 'アクション';
let AD_ADD_CANCEL = 'キャンセル';
let AD_ADD_CONFIRM = '確定';
let AD_ADD_NEW_ADS = '新規広告';
let AD_ADD_NEW_ADVERTISEMENT_DIALOG = '新規広告';
let AD_ADVERTISEMENT_UPDATE_FAILED = '更新が失敗しました。もう一度お試しください。';
let AD_ADVERTISEMENT_UPDATE_IN_PROGRESS = '更新中';
let AD_ADVERTISEMENT_UPDATE_SUCCESS = '更新完了';

let AD_CATEGORY_NAME = 'カテゴリー';
let AD_CONFIGURE_SLOT = '広告位置';
let AD_EDIT_ADVERTISEMENT_DIALOG = '広告編集';
let AD_EDIT_CANCEL = 'キャンセル';
let AD_EDIT_CONFIRM = '確定';
let AD_FOOD_CATEGORY_LOADING = 'カテゴリーを読み込み中';
let AD_FOOD_DETAIL_LOADING = 'メニューを読み込み中';
let AD_FOOD_DETAIL_NAME = 'メニュー詳細';

let AD_INVALID_RESTAURANT = '必須な項目です。レストランを選択してください。';
let AD_INVALID_FOOD_CATEGORY = '必須な項目です。カテゴリーを選択してください。';
let AD_INVALID_FOOD_DETAILS = '必須な項目です。メニュー詳細を選択してください。';
let AD_LIST_LOADING_ADVERTISEMENT = '広告を読み込み中';
let AD_NAME = '広告名';
let AD_IMAGE = '画像';
let AD_PLEASE_SELECT = '選択';

let AD_TAG = 'タグ';
let ADD_IMAGE = '画像を追加する';
let ADD_IMAGE_APPENDIX = '(推奨のサイズは1MB以下(PNG, JPG可))';

let ADVERTISEMENT_DELETE = '削除';
let ADVERTISEMENT_DELETE_CANCEL = 'キャンセル';
let ADVERTISEMENT_DELETE_CONFIRM = '確定';
let ADVERTISEMENT_DELETE_EXPLAIN = '広告を削除しますか？';

let ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY = '必須な項目です。画像をアップロードしてください。';
let ADVERTISEMENT_NAME_IS_MISSING_ERROR = '入力が必須な項目です。広告名を入力してください。';
let COL_AD_ACTION = 'アクション';
let COL_AD_CONFIGURE_SLOT = '広告位置';
let COL_AD_DESTINATION = '移動先';
let COL_AD_EDIT = '';
let COL_IMAGE = '画像';
let COL_TITLE = '広告名';
let COL_RESTAURANT_NAME = 'レストラン';
let DONE = 'DONE';
let IN_PROGRESS = '読み込み中';
let REQUIRED = 'REQUIRED';
let SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA = '更新が失敗しました。もう一度お試しください。';

let YOU_HAVE_NO_ORDERS_AT_THE_MOMENT = '広告はありません';

let AD_INVALID_AD_DESTINATION = '無効な移動先です。';

// 広告位置
let AD_ORDER_LIST = '注文リスト';
let AD_FOOD_MENU = 'メニュー';
let AD_FOOD_DETAIL = 'メニュー詳細';

let AD_ACTION_GO_TO_FOOD_MENU = 'カテゴリー';
let AD_ACTION_GO_TO_FOOD_DETAIL = 'メニュー詳細';

let DELETE_ADVERTISEMENT_IN_PROGRESS = 'DELETE_ADVERTISEMENT_IN_PROGRESS';
let DELETE_ADVERTISEMENT_DONE = 'DELETE_ADVERTISEMENT_DONE';
let DELETE_ADVERTISEMENT_ERROR = 'DELETE_ADVERTISEMENT_ERROR';

let UPDATE_ADVERTISEMENT_ERROR = 'UPDATE_ADVERTISEMENT_ERROR';
let UPDATE_ADVERTISEMENT_IN_PROGRESS = 'UPDATE_ADVERTISEMENT_IN_PROGRESS';
let UPDATE_ADVERTISEMENT_DONE = 'UPDATE_ADVERTISEMENT_DONE';

// let AD_ORDER_LIST = '注文リスト';
// let AD_FOOD_MENU = 'メニュー';
// let AD_FOOD_DETAIL = 'メニュー詳細';

export default {
  AD_ACTION_GO_TO_FOOD_MENU,
  AD_ACTION_GO_TO_FOOD_DETAIL,
  AD_INVALID_RESTAURANT,
  ADD_IMAGE_APPENDIX,
  UPDATE_ADVERTISEMENT_IN_PROGRESS,
  UPDATE_ADVERTISEMENT_ERROR,
  UPDATE_ADVERTISEMENT_DONE,
  DELETE_ADVERTISEMENT_IN_PROGRESS,
  DELETE_ADVERTISEMENT_DONE,
  DELETE_ADVERTISEMENT_ERROR,
  AD_IMAGE,
  AD_ORDER_LIST,
  AD_FOOD_MENU,
  AD_FOOD_DETAIL,
  YOU_HAVE_NO_ORDERS_AT_THE_MOMENT,
  AD_INVALID_AD_DESTINATION,
  SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
  AD_PLEASE_SELECT,
  AD_INVALID_FOOD_CATEGORY,
  AD_INVALID_FOOD_DETAILS,
  ADD_IMAGE,
  REQUIRED,
  IN_PROGRESS,
  DONE,
  ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY,
  AD_ADD_NEW_ADVERTISEMENT_DIALOG,
  ADVERTISEMENT_NAME_IS_MISSING_ERROR,
  ADVERTISEMENT_DELETE_CANCEL,
  ADVERTISEMENT_DELETE_CONFIRM,
  ADVERTISEMENT_DELETE_EXPLAIN,
  ADVERTISEMENT_DELETE,
  AD_ADD_NEW_ADS,
  AD_LIST_LOADING_ADVERTISEMENT,
  AD_FOOD_CATEGORY_LOADING,
  AD_FOOD_DETAIL_LOADING,
  AD_ACTION_LOADING,
  AD_ADVERTISEMENT_UPDATE_IN_PROGRESS,
  AD_ADVERTISEMENT_UPDATE_SUCCESS,
  AD_ADVERTISEMENT_UPDATE_FAILED,
  AD_ACTIONS,
  AD_ADD_CANCEL,
  AD_ADD_CONFIRM,
  AD_CATEGORY_NAME,
  AD_CONFIGURE_SLOT,
  AD_EDIT_ADVERTISEMENT_DIALOG,
  AD_EDIT_CANCEL,
  AD_EDIT_CONFIRM,
  AD_FOOD_DETAIL_NAME,
  AD_NAME,
  AD_TAG,
  COL_AD_ACTION,
  COL_AD_CONFIGURE_SLOT,
  COL_AD_DESTINATION,
  COL_AD_EDIT,
  COL_IMAGE,
  COL_TITLE,
  COL_RESTAURANT_NAME,
};
