import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WarningIcon from '@material-ui/icons/Warning';

import active_lang from 'src/langs/jp_en';

import getRestaurantById from 'src/utils/getRestaurantById';

import ShowDebugJson from 'src/components/ShowDebugJson';
import ShowDebug from 'src/components/ShowDebug';
import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: {
    color: LYNKED_RED,
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
  user_input_error: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
}));

export default function SelectRestaurant({
  formik,
  all_restaurants_result,
  rest_id_to_skip,
}) {
  let classes = useStyles();

  let restaurant_list = all_restaurants_result.data;

  let ad_window_default_select = { 32: 'food_menu', 4: 'food_menu' };

  let [
    meny_service_class_selected,
    setMenyServiceClassSelected,
  ] = React.useState();

  const lookupDefaultAdWindowByServiceClass = service_class => {
    return ad_window_default_select[service_class];
  };

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <Select
            id="src_rest_id"
            {...formik.getFieldProps('src_rest_id')}
            input={<InputBase />}
            IconComponent={KeyboardArrowDownIcon}
            style={{ paddingTop: '0.5rem' }}
            defaultValue={'please_select'}
          >
            <MenuItem value={'please_select'} disabled>
              {active_lang.AD_PLEASE_SELECT}
            </MenuItem>
            {restaurant_list
              .filter(x => x.id !== rest_id_to_skip)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((r, idx) => {
                return (
                  <MenuItem value={r.id}>
                    {r.name} ({r.data?.meny_service_class?.name})
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
