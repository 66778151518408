import React from 'react';
import { Box } from '@material-ui/core';

import AdminSelectMenyAdConfigureSlot from './AdminSelectMenyAdConfigureSlot';
import SelectRestaurant from './SelectRestaurant';

import ShowDebug from 'src/components/ShowDebug';

import { isEqual } from 'lodash-es';
import SelectAdAction from './SelectAdAction';
import SelectAdDestination from './SelectAdDestination';
import SelectAdDestinationSubform from './SelectAdDestinationSubform';
import ShowDebugJson from 'src/components/ShowDebugJson';
import SelectFoodDetail from './SelectFoodDetail';
import SelectFoodMenuCategory from './SelectFoodMenuCategory';
import AdConfigureSlotSubform from './AdConfigureSlotSubform';

function getDataById(all_data, wanted_id) {
  let found = false;
  let found_data = {};
  for (let i = 0; i < all_data.length; i++) {
    if (all_data[i].id == wanted_id) {
      found = true;
      found_data = all_data[i];
      break;
    }
  }

  if (found) {
    return found_data;
  } else {
    return undefined;
  }
}

export default function FormMenyAdConfig({
  formik,
  food_details,
  all_advertisement_result,
}) {
  return (
    <>
      <ShowDebug>form meny ad config</ShowDebug>
      <AdminSelectMenyAdConfigureSlot
        formik={formik}
        all_advertisement_result={all_advertisement_result}
      />

      <AdConfigureSlotSubform formik={formik} food_details={food_details} />
    </>
  );
}
