import { isUndefined } from 'lodash-es';
import getDataById from './getDataById';
function getRestaurantById(all_restaurants_result, rest_id) {
  console.log('getRestaurantById', 'rest_id', rest_id);
  if (isUndefined(getDataById(all_restaurants_result, rest_id)))
    return undefined;

  return getDataById(all_restaurants_result, rest_id).data;
}

export default getRestaurantById;
