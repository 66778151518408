import 'firebase/firestore';

import firebase from 'firebase/app';
import db_config from '../configs/db_config';
import db from '../Firebase/db';

import beers_ads_png from './beers_ads_png';

const FOOD_MENU = { id: 1, name: 'food_menu' };
const FOOD_DETAIL = { id: 2, name: 'food_detail' };
const ORDER_LIST = { id: 3, name: 'order_list' };

const ad_window_options = [FOOD_MENU, FOOD_DETAIL, ORDER_LIST];

const select_values = {
  is_enabled: { ENABLED: true, DISABLED: false },
  ad_window: {
    FOOD_MENU: { id: 1, name: 'food_menu' },
    FOOD_DETAIL: { id: 2, name: 'food_detail' },
    ORDER_LIST: { id: 3, name: 'order_list' },
  },
  ad_type: {
    AD_TYPE_MENYLIGHT_ADVERTISEMENT: 'AD_TYPE_MENYLIGHT_ADVERTISEMENT',
    AD_TYPE_MENY_REST_ADVERTISEMENT: 'AD_TYPE_MENY_REST_ADVERTISEMENT',
    AD_TYPE_REST_DEFINE_ADVERTISEMENT: 'AD_TYPE_REST_DEFINE_ADVERTISEMENT',
  },
  ad_action: {
    FOOD_MENU: { id: 1, name: 'food_menu' },
    FOOD_DETAIL: { id: 2, name: 'food_detail' },
  },
};

const default_ad_config = {
  data: {
    title: '',
    image_url: ['', '', ''],
  },
};

// console.log(JSON.stringify(beers_ads_png));

const ad_db_ref = db.collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG);
const ADVERTISEMENT_REF = db.collection(
  db_config.DB_TABLE_ADVERTISEMENT_CONFIG
);

function getAdvertisementByRestId(rest_id) {
  return db
    .collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG)
    .where('target_restaurants', 'array-contains-any', ['all', rest_id]);
}

function getAllAdvertisement() {
  return ADVERTISEMENT_REF.get().then(result => {
    return result.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
  });
}

function listAllAdvertisement() {
  return ADVERTISEMENT_REF.get().then(result => {
    return result.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
  });
}

// admin advertisement
function listAllKLynkedBottomAdvertisement() {
  return ADVERTISEMENT_REF.where(
    'ad_type',
    '==',
    'AD_TYPE_LYNKED_BOTTOM_ADVERTISEMENT'
  )
    .get()
    .then(result => {
      return result.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function listAllMenyLightAdvertisement() {
  return ADVERTISEMENT_REF.where(
    'ad_type',
    '==',
    select_values.ad_type.AD_TYPE_MENYLIGHT_ADVERTISEMENT
  )
    .get()
    .then(result => {
      return result.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function getAdvertisement(doc_id) {
  return ad_db_ref.doc(doc_id).get();
}

function updateAdvertisement(doc_id, ad_info_value) {
  return ad_db_ref.doc(doc_id).set({ ...ad_info_value });
}

function updateAdvertisementById(advertisement_id, ad_info_value) {
  console.log('updateAdvertisementById', 'advertisement_id', advertisement_id);

  let DocRef = ad_db_ref.doc(advertisement_id);
  return db
    .runTransaction(transaction => {
      return transaction.get(DocRef).then(sfDoc => {
        DocRef.set({ ...ad_info_value }, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch(error => {
      console.log('Transaction failed: ', advertisement_id, error);
    });
}

function getNewAdvertisementId() {
  return new Promise(res => {
    res(ad_db_ref.doc().id);
  });
}

function addAdvertisement(new_ad_config) {
  return ad_db_ref.add(new_ad_config);
}

function getFoodMenuAdvertisements(rest_id) {
  return getAdvertisementByRestId(rest_id)
    .where('ad_window', '==', 'food_menu')
    .get()
    .then(result => {
      return result.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function deleteAdvertisementById(advertisement_id) {
  console.log('deleteAdvertisementById', 'advertisement_id', advertisement_id);
  let DocRef = ad_db_ref.doc(advertisement_id);
  return db
    .runTransaction(transaction => {
      return transaction.get(DocRef).then(sfDoc => {
        DocRef.delete();
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch(error => {
      console.log('Transaction failed: ', advertisement_id, error);
    });
}

function getFoodDetailAdvertisements(rest_id) {
  return getAdvertisementByRestId(rest_id)
    .where('ad_window', '==', 'food_detail')
    .get()
    .then(result => {
      return result.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function addAdvertisementByAdvertisementId(advertisement_id, payload) {
  // return ad_db_ref.doc(advertisement_id).set(payload);

  // alert('helloworld');
  let DocRef = ad_db_ref.doc(advertisement_id);

  return db
    .runTransaction(transaction => {
      return transaction.get(DocRef).then(sfDoc => {
        transaction.set(
          DocRef,
          {
            ...payload,
            ad_create_time: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      });
    })
    .then(() => {
      console.log('setAdvertisementByAdvertisementId committed!');
    })
    .catch(error => {
      console.log('setAdvertisementByAdvertisementId failed: ', payload, error);
    });
}

function setAdvertisementByAdvertisementId(advertisement_id, payload) {
  // return ad_db_ref.doc(advertisement_id).set(payload);

  // alert('helloworld');
  let DocRef = ad_db_ref.doc(advertisement_id);

  return db
    .runTransaction(transaction => {
      return transaction.get(DocRef).then(sfDoc => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('setAdvertisementByAdvertisementId committed!');
    })
    .catch(error => {
      console.log('setAdvertisementByAdvertisementId failed: ', payload, error);
    });
}

export {
  ad_window_options,
  select_values,
  addAdvertisement,
  beers_ads_png,
  default_ad_config,
  deleteAdvertisementById,
  getAdvertisement,
  getAllAdvertisement,
  getFoodDetailAdvertisements,
  getFoodMenuAdvertisements,
  getNewAdvertisementId,
  listAllAdvertisement,
  listAllKLynkedBottomAdvertisement,
  listAllMenyLightAdvertisement,
  updateAdvertisement,
  updateAdvertisementById,
  setAdvertisementByAdvertisementId,
  addAdvertisementByAdvertisementId,
};
