import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import NavBar from './NavBar';
import TopBar from './TopBar';

import { AuthContext } from 'src/Firebase/context';
import { isNull, isUndefined } from 'lodash-es';

import writeLog from 'src/helpers/writeLog';
import { CHECK_LOGIN_TIMEOUT_MS } from 'src/config';

import { checkValidLynkedUser } from 'src/utils/checkValidLynkedUser';

import { field_values } from 'src/modals/logging';

const PUBLIC_URL = process.env.PUBLIC_URL;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  let { user } = React.useContext(AuthContext);
  let [confirm_loaded, setConfirmLoaded] = React.useState(false);

  React.useEffect(() => {
    if (confirm_loaded) {
      if (checkValidLynkedUser(user)) {
        // not valid user found
        console.log('2nd check user pass', user);
        writeLog({
          event_type: field_values.event_type.ADMIN_USER_LOGIN,
          content: { user },
        });
      } else {
        console.log('2nd check user failed', user);
        console.log(`${PUBLIC_URL}/login`);
        navigate(`${PUBLIC_URL}/login`, { replace: true });
      }
    }
  }, [confirm_loaded]);

  React.useEffect(() => {
    console.log('DashboardLayout', 'index.js', 'starting');
    setTimeout(() => {
      setConfirmLoaded(true);
    }, CHECK_LOGIN_TIMEOUT_MS);
  }, []);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
