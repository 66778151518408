import React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import active_lang from 'src/langs/jp_en';
import ShowDebug from 'src/components/ShowDebug';
import { isUndefined } from 'lodash-es';
import WarningIcon from '@material-ui/icons/Warning';
import { Transition } from './styles';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: {
    color: LYNKED_RED,
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
  user_input_error: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
}));

const AdWindowInput = withStyles(() => ({}))(InputBase);

export default function SelectFoodMenuCategory({ formik, food_details }) {
  let classes = useStyles();

  let { data } = food_details;

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <Box>
            {active_lang.AD_CATEGORY_NAME}
            <ShowDebug>AD_CATEGORY_NAME</ShowDebug>
          </Box>
          <Select
            id="form_ad_destination"
            {...formik.getFieldProps('form_ad_destination')}
            value={formik.values.form_ad_destination}
            classes={{
              root: classes.food_availability,
              icon: classes.icon,
            }}
            input={<AdWindowInput />}
            IconComponent={KeyboardArrowDownIcon}
            style={{ paddingTop: '0.5rem' }}
            defaultValue={'please_select'}
          >
            <MenuItem value={'please_select'} disabled>
              {active_lang.AD_PLEASE_SELECT}
            </MenuItem>
            {Object.keys(data.columns)
              .sort()
              .map((k, idx) => {
                let column = data.columns[k];
                return (
                  <MenuItem value={column.id} key={idx}>
                    {column.title}
                  </MenuItem>
                );
              })}
          </Select>
          {formik.touched.form_ad_destination &&
          formik.errors.form_ad_destination ? (
            <Box className={classes.user_input_error}>
              <Box pr="1rem">
                <WarningIcon fontSize="small" />
              </Box>
              <Box>{active_lang.AD_INVALID_FOOD_CATEGORY}</Box>
            </Box>
          ) : null}
        </FormControl>
      </Box>
    </>
  );
}
