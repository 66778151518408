import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';
import { UserPlus } from 'react-feather';

import AddNewAdvertisementTag from './AddNewAdvertisementTag';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, refreshAddAdvertisementTag, ...rest }) => {
  const classes = useStyles();

  const [
    open_new_advertisement_tag,
    setOpenNewAdvertisementTag,
  ] = React.useState(false);

  const handleAddNewAdvertisementTag = () => {
    setOpenNewAdvertisementTag(true);
  };

  return (
    <>
      <AddNewAdvertisementTag
        refreshAddAdvertisementTag={refreshAddAdvertisementTag}
        open={open_new_advertisement_tag}
        setOpen={setOpenNewAdvertisementTag}
      />
      <div className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            startIcon={<UserPlus />}
            onClick={e => handleAddNewAdvertisementTag(e)}
          >
            {active_lang.NEW_ADVERTISEMENT_TAG_BUTTON}
          </Button>
        </Box>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
