import React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import active_lang from 'src/langs/jp_en';
import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { useStyles, Transition } from './styles';

import SelectFoodMenuCategory from './SelectFoodMenuCategory';
import SelectFoodDetail from './SelectFoodDetail';

const AdWindowInput = withStyles(() => ({}))(InputBase);

export default function SelectAdDestination({ formik, food_details }) {
  let classes = useStyles();

  return (
    <>
      <SelectFoodMenuCategory formik={formik} food_details={food_details} />
      <SelectFoodDetail formik={formik} food_details={food_details} />
    </>
  );
}
