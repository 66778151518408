import React from 'react';
import { QRCode } from 'react-qr-svg';

import { Box, Typography } from '@material-ui/core';

import { HTML_COLOR_NAMES_LENGTH, HTML_COLOR_NAMES } from 'src/consts';
import getRandomInt from 'src/utils/getRandomInt';
import getRandomColor from 'src/utils/getRandomColor';

export default function GetQR({ link, text }) {
  let random_idx = getRandomInt(HTML_COLOR_NAMES_LENGTH);
  let random_color = HTML_COLOR_NAMES[random_idx];
  let random_color_name = random_color[0];
  let random_color_value = random_color[1];

  return (
    <>
      <Box style_del={{ marginTop: '5rem', marginBottom: '5rem' }}>
        <Box style_del={{ marginBottom: '1rem' }}></Box>
      </Box>

      <Box
        style_del={{
          marginTop: '5rem',
          marginBottom: '5rem',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box></Box>
        <Box>
          <a href={link} target="_blank">
            <Box
              style_del={{
                border: '1px',
                borderStyle: 'dashed',
                padding: '4rem',
              }}
            >
              <Box>
                <Typography variant="caption" color="textPrimary">
                  {text}
                </Typography>
              </Box>

              <Box>
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor={getRandomColor()}
                  level="Q"
                  style={{ width: '100%' }}
                  value={link}
                />
              </Box>
              <Typography variant="caption" color="textPrimary">
                {text}
              </Typography>
            </Box>
          </a>
        </Box>
        <Box></Box>
      </Box>
    </>
  );
}
