import { useMutation } from 'react-query';
import { updateRestaurantConfigByRestId } from 'src/modals/restaurant_config';

export default function useUpdateRestaurantInfo() {
  const mutation = useMutation(({ rest_id, payload }) => {
    console.log('useUpdateRestaurantInfo', 'rest_id', rest_id);
    console.log('useUpdateRestaurantInfo', 'payload', payload);
    return updateRestaurantConfigByRestId(rest_id, payload);
  });

  return mutation;
}
