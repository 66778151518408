import { isUndefined, isNull } from 'lodash-es';

const checkValidEmail = email => email.search('@lynked.co.jp') > -1;
const checkValidLynkedUser = user => {
  /**
   * return true when valid user found
   */
  if (isUndefined(user)) return false;
  if (isNull(user)) return false;
  if (!checkValidEmail(user.email)) return false;

  return true;
};

export { checkValidLynkedUser };
