import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';

import Page from 'src/components/Page';

import { useStyles } from './styles';
import useQueryAllAdvertisement from 'src/hooks/useQueryAllAdvertisement';
import useQueryAllLynkedBottomAdvertisement from 'src/hooks/useQueryAllLynkedBottomAdvertisement';
import useQueryListAllFoodDetails from 'src/hooks/useQueryListAllFoodDetails';
import useQueryListAllRestaurants from 'src/hooks/useQueryListAllRestaurants';

import Toolbar from './Toolbar';

import active_lang from 'src/langs/jp_en';

import ListAdvertisements from './ListAdvertisements';

export default function EditAdvertisementInfo() {
  const classes = useStyles();

  // const all_advertisement_result = useQueryAllAdvertisement();
  const all_advertisement_result = useQueryAllLynkedBottomAdvertisement();
  const all_food_details_result = useQueryListAllFoodDetails();
  const all_restaurants_result = useQueryListAllRestaurants();

  return (
    <>
      <Page
        className={classes.root}
        title={active_lang.ADMIN_ADVERTISEMENT_ACTIONS}
      >
        <Container maxWidth={false}>
          {all_advertisement_result.isLoading ||
          all_food_details_result.isLoading ||
          all_restaurants_result.isLoading ? (
            <></>
          ) : (
            <>
              <Toolbar
                handleListAllRestaurants={`handleListAllRestaurants`}
                all_food_details_result={all_food_details_result}
                advertisement_infos={all_advertisement_result.data}
                all_advertisement_result={all_advertisement_result}
                all_restaurants_result={all_restaurants_result}
                refetch={all_advertisement_result.refetch}
              />
              <Box mb="1rem">
                <Typography variant="h4" color="textPrimary">
                  {active_lang.ADMIN_EDIT_ADVERTISEMENT_INFO}
                </Typography>
              </Box>

              <Box>
                <ListAdvertisements
                  all_food_details_result={all_food_details_result}
                  advertisement_infos={all_advertisement_result.data}
                  all_advertisement_result={all_advertisement_result}
                  all_restaurants_result={all_restaurants_result}
                />
              </Box>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
