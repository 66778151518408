import { Box, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { UserPlus } from 'react-feather';
import AddNewUserDialog from 'src/components/AddNewUserDialog';
import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, handleListAllUsers, ...rest }) => {
  const classes = useStyles();
  let [open_new_user_dialog, setOpenNewUserDialog] = React.useState(false);

  return (
    <>
      <AddNewUserDialog
        open={open_new_user_dialog}
        setOpen={setOpenNewUserDialog}
        handleListAllUsers={handleListAllUsers}
      />
      <Box className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
          <Box style={{ display: 'none' }}>
            <Button className={classes.importButton} disabled>
              Import
            </Button>
            <Button className={classes.exportButton} disabled>
              Export
            </Button>
          </Box>
          <Button
            color="primary"
            variant="contained"
            startIcon={<UserPlus />}
            onClick={e => {
              setOpenNewUserDialog(true);
            }}
          >
            {active_lang.ADMIN_ADD_NEW_USER}
          </Button>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
