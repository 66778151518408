import { isUndefined } from 'lodash-es';

function getAdActionObjByName(name_in) {
  let sanitized_ad_action = { id: 1, name: 'food_menu' };
  switch (name_in) {
    case 'food_menu':
      sanitized_ad_action = { id: 1, name: 'food_menu' };
      break;
    case 'food_detail':
      sanitized_ad_action = { id: 2, name: 'food_detail' };
      break;
    default:
      break;
  }
  return sanitized_ad_action;
}

export default getAdActionObjByName;
