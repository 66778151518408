import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Button,
} from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import { lookupUserServiceName } from 'src/configs/meny_service';

import EditUserDialog from './EditUserDialog';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import RestaurantLinkDialog from './RestaurantLinkDialog';

// import { UserTableRow } from './UserTableRow';

import active_lang from 'src/langs/jp_en';
import { isUndefined } from 'lodash';

// const default_pagination_limit = 500;

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function ListUsers({ className, users, handleListAllUsers, ...rest }) {
  const classes = useStyles();
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  // const [limit, setLimit] = useState(default_pagination_limit);
  // const [page, setPage] = useState(0);

  const [open_edit_user_dialog, setOpenEditUserDialog] = React.useState(false);
  const [edit_user, setEditUser] = React.useState(null);
  const [
    open_delete_confirm_dialog,
    setOpenDeleteConfirmDialog,
  ] = React.useState(false);
  const [delete_user, setDeleteUser] = React.useState(null);

  const [link_user, setLinkUser] = React.useState({
    uid: '',
  });
  const [open_link_dialog, setOpenLinkDialog] = React.useState(null);

  // const [user_info_to_edit, setUserInfoToEdit] = React.useState({
  //   name: '',
  //   email: '',
  // });

  const handleEditClick = customer => {
    // alert(customer.uid);
    setEditUser(customer);
    console.log('customer', customer);
    setOpenEditUserDialog(true);
  };
  const handleDeleteClick = customer => {
    // alert(customer.uid);
    setDeleteUser(customer);
    setOpenDeleteConfirmDialog(true);
  };

  const handleLinkClick = customer => {
    setLinkUser(customer);
    setOpenLinkDialog(true);
  };

  return (
    <>
      <EditUserDialog
        open={open_edit_user_dialog}
        setOpen={setOpenEditUserDialog}
        edit_user={edit_user}
        handleListAllUsers={handleListAllUsers}
      />
      <DeleteConfirmDialog
        open={open_delete_confirm_dialog}
        setOpen={setOpenDeleteConfirmDialog}
        delete_user={delete_user}
        handleListAllUsers={handleListAllUsers}
      />
      <RestaurantLinkDialog
        open={open_link_dialog}
        setOpen={setOpenLinkDialog}
        link_user={link_user}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}></Box>
        </PerfectScrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>actions</TableCell>
              <TableCell>{active_lang.ADMIN_USER_EMAIL}</TableCell>
              <TableCell>{'disabled'}</TableCell>
              <TableCell>{'creationTime'}</TableCell>
              <TableCell>{'lastSignInTime'}</TableCell>
              <TableCell>{'providerId'}</TableCell>
              <TableCell>{'meny_class'}</TableCell>
              <ShowDebug>
                <TableCell style={{ width: '10px' }}>{'debug'}</TableCell>
              </ShowDebug>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <>
                <TableRow name={user.id} hover key={user.id}>
                  <TableCell>
                    <Button onClick={() => handleEditClick(user)}>E</Button>
                    <Button onClick={() => handleDeleteClick(user)}>D</Button>
                    <Button onClick={() => handleLinkClick(user)}>L</Button>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{JSON.stringify(user.disabled)}</TableCell>
                  <TableCell>
                    {isUndefined(user.metadata)
                      ? ''
                      : moment
                          .utc(user.metadata.creationTime)
                          .tz(moment.tz.guess())
                          .format('DD-MM-YYYY h:mm:ss A')}
                  </TableCell>
                  <TableCell>
                    {isUndefined(user.metadata)
                      ? ''
                      : moment(user.metadata.lastSignInTime)
                          .tz(moment.tz.guess())
                          .format('DD-MM-YYYY h:mm:ss A')}
                  </TableCell>
                  <TableCell>
                    {isUndefined(user.metadata) ||
                    isUndefined(user.providerData[0])
                      ? ''
                      : user.providerData[0].providerId}
                  </TableCell>
                  <TableCell>
                    {isUndefined(user.customClaims)
                      ? 'not set'
                      : lookupUserServiceName(user.customClaims.meny_class)}
                  </TableCell>
                  <ShowDebug>
                    <TableCell>
                      <pre style={{ width: '10px', height: '10px' }}>
                        {JSON.stringify(user, null, 2)}
                      </pre>
                    </TableCell>
                  </ShowDebug>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  );
}

export default ListUsers;
